var financialOperation = {
    processCashChange: function () {
        var $el = $(this);
        $('[name="cost_sum"]').attr('readonly', null);
        var $container = $el.closest('form').find('.dynamic-part');
        $.get($el.data('url'), {"cash_type": $el.val()}, function (res) {
            $container.html(res);
        });
    },
    processTypeChange: function (e) {
        var $el = $(e.target);
        $('[name="cost_sum"]').attr('readonly', null);
        var self = this;

        $.get($el.data('url'), {
            "type": $el.val(),
            "cash_type": $el.closest('form').find('.cash-type').val()
        }, self.showLastDynamicPart.bind($el));
    },
    showLastDynamicPart: function (data) {
        this.closest('form').find('.dynamic-extra-part').html(data);
    },
    loadEditForm: function (e) {
        e.preventDefault();

        var $self = $(this),
            $modal = $("#updateCostModal"),
            $subtypes;

        $.get($self.closest('tr').data('url'), function (cost) {
            $modal.find('#costID').text(cost.id);
            $modal.find('[name="cost_type"]').val(cost.subtype.cost_types_id);
            $modal.find('[name="cost_extra"]').val(cost.comment);
            $subtypes = $modal.find('[name="cost_subtype"]');

            $.get($modal.find('#cost_type').data('url'), {"type": cost.subtype.cost_types_id}, function (data) {
                insertCostSubtypes(data);
                $subtypes.val(cost.cost_subtypes_id);
                $modal.find('form').attr('action', $self.closest('tr').data('update-url'));
                $modal.modal('show');
            });
        });
    },
    loadDividends: function (e) {
        var $el = $(e.target);
        var founder = $el.closest('form').find('.cost-subtype').val();
        this.updateDividendsSum($el.closest('form').find('[name="cost_sum"]'), $el.val(), founder);
    },
    processSubtypeChange: function (e) {
        var $el = $(e.target);
        var subtype = $el.val();
        var type = $el.closest('form').find('.cost-type').val();
        var self = this;

        if ('dividends' == type && $.inArray(subtype, ['alex', 'darya', 'roman']) !== -1) {
            var founder = subtype;
            var amount = $el.closest('form').find('.dividends-amount').val();
            self.updateDividendsSum($el.closest('form').find('[name="cost_sum"]'), amount, founder);
        }
    },
    updateDividendsSum: function ($field, amount, founder) {
        var factors = {
            'alex': 30000,
            'darya': 25000,
            'roman': 45000
        };
        $field.val(factors[founder] * amount).attr('readonly', 'readonly');
    }
};