var calendar = {
    loadEventsOnDate: function (e) {
        var $el = $(e.currentTarget);
        if ($el.hasClass('active')) {
            return;
        }
        $el.closest('tbody').find('td').removeClass('active');
        $el.addClass('active');
        var date = $el.data('date');
        var $eventsOnDateContainer = $('.event-on-date-container');
        var url = $eventsOnDateContainer.data('url');

        $eventsOnDateContainer.load(url + '?date=' + date, function (res) {
            activateSlider($eventsOnDateContainer.find('.slider-container'));
        })
    },

    loadModalOnDate: function (e) {
        var $el = $(e.currentTarget);
        var date = $el.data('date');
        var $modal = $('.modal-resource');
        var url = $modal.data('url');
        var ruDate;
        $modal.find('.modal-body').load(url + '?date=' + date, function (res) {
            ruDate = $('input[name="date"]').val();
            $modal.find('.modal-header-text').text('Мероприятия ' + ruDate);
            $modal.modal('show');
        });
    },

    deleteEvent: function (e) {
        e.preventDefault();

        var $el = $(e.target);
        if (!$el.is('a')) {
            $el = $el.closest('a');
        }
        var id = $el.data('id');
        var url = $el.closest('.modal').find('form').attr('action');

        $.ajax(url + '/' + id, {
            method: 'DELETE',
            success: function (res) {
                $el.closest('.item-event').remove();
                toastr.success('Событие удалено!');
            }
        });
    },

    createEventOnDate: function (e) {
        e.preventDefault();
        var $form = $(e.target);

        $.post($form.attr('action'), $form.serialize(), function (res) {
            $('.list-events').append(res);
            toastr.success('Событие добавлено!');
        });
    },

    loadMonth: function (e) {
        e.preventDefault();
        var $el = $(e.target);
        var url = $el.attr('href');
        var linkUrlObject = new UrlObject(url);
        var $container = $('.calendar-container');
        var pageUrlObject;
        $container.load(url, function (res) {
            pageUrlObject = new UrlObject(window.location.href);
            pageUrlObject.setParam('month', linkUrlObject.getParam('month'));
            pageUrlObject.setParam('year', linkUrlObject.getParam('year'));
            window.history.pushState(null, null, pageUrlObject.getUrl());
        });
    }
};