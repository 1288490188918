/**
 * Показывает текст ошибки (используется для глобальных настроек ajax
 *
 * @param x
 * @param e
 * @constructor
 */
function ajaxErrorHandler(x, e) {
    toastr.error('Что-то пошло не так - попробуйте еще раз!');
}

/**
 * Форматирует число, отделяя по 3 знака
 * @param  {[type]} number [description]
 * @return {[type]}        [description]
 */
function currencyFormat(number) {
    var strFromNumber = $.trim(number);

    var separation = 0;
    var formatedArray = [];
    for (var i = strFromNumber.length - 1; i >= 0; i--) {

        formatedArray.push(strFromNumber[i]);
        if (++separation == 3 && i != 0) {
            formatedArray.push(" ");
            separation = 0;
        }
    }

    return formatedArray.reverse().join("") + String.fromCharCode(160) + "руб.";
}

/**
 * Возвращает число, полученное из строки, или 0 если это не возможно
 * @param  {[type]} a [description]
 * @return {[type]}   [description]
 */
function toNum(a) {
    return isNaN(parseInt(a)) ? 0 : parseInt(a);
}

/**
 * Увеличивает числовую часть строки на единицу и возвращает новую строку
 *
 * @param str
 * @returns {string|void|XML}
 */
function increaseNumberInString(str) {
    var matchObject = str.match(/\d+/);
    if (!matchObject) throw new Error("Can't find digits in string: " + str);

    return str.replace(matchObject[0], ++matchObject[0]);
}

function isset(variable) {
    return typeof variable != "undefined" && variable != null;
}

function trimSpaces(str) {
    return str.replace(/\s+/g, '');
}

function activateSlider($obj) {
    $obj.slick({
        arrows: false,
        autoplay: true,
        fade: true,
        dots: true,
        customPaging: function (slider, i) {
            return '<i class="pin" style="background-color:' + $(slider.$slides[i]).data('color') + '"></i>';
        }
    });
}

/**
 * Видимо приводит дату к определенному виду, хотя я не уверен 23.01.2015 egolife
 * @param  {[type]} i [description]
 * @return {[type]}   [description]
 */
var formatDate = function (i) {
    var elem = $(this);
    var date_and_time = elem.val() ? $.trim(elem.val()).split(" ") : $.trim(elem.text()).split(" ");
    var year_month_day = date_and_time[0].split("-").reverse();

    if (!date_and_time[1]) {
        date_and_time = year_month_day.join(".").replace(/[\s]/g, "");
    } else {
        date_and_time = year_month_day.join(".") + " " + date_and_time[1];
    }
    elem.val() ? elem.val(date_and_time) : elem.text(date_and_time);
};

/**
 * Return time strnig in format Y-m-d H:i:s ??? may be
 *
 * @param dateStr
 * @returns {string}
 */
function toIso(dateStr) {
    var dateTimeArray = dateStr.split(' ');
    if (1 == dateTimeArray.length) {
        return dateStr.split('.').reverse().join('-');
    }

    return dateTimeArray[0].split('.').reverse().join('-') + ' ' + dateTimeArray[1];
}

//JS object to work with urls, get/set GET params, get/set hash
var UrlObject = function (url) {
    var that = {};
    that.origUrl = url;
    that.params = [];
    that.hash = '';
    var params = url.indexOf('?') != -1 ? url.split('?')[1] : '';
    var hash;
    if (params) {
        hash = params.split('#')[1];
        params = params.split('#')[0];
        that.params = params.split('&');
    }
    if (hash) {
        that.hash = '#' + hash;
    }
    that.url = url;

    var methods = {
        getParam: function (name) {
            for (var i = 0; i < this.params.length; i++) {
                var val = this.params[i].split(name + '=')[1]
                if (val) {
                    return val;
                }
            }
            return null;
        },
        setParam: function (name, value) {
            for (var i = 0; i < this.params.length; i++) {
                var val = this.params[i].split(name + '=')[1]
                if (val) {
                    this.params[i] = name + '=' + value;
                    break;
                }
            }
            if (!val) {
                this.params.push(name + '=' + value);
            }
            this._generateUrl();
        },
        deleteParam: function (name) {
            for (var i = 0; i < this.params.length; i++) {
                var val = this.params[i].split(name + '=')[1];
                if (val) {
                    this.params.splice(i, 1);
                    break;
                }
            }
            this._generateUrl();
        },
        getUrl: function () {
            return this.url;
        },
        getOrigUrl: function () {
            return this.origUrl;
        },
        getHash: function () {
            return this.hash;
        },
        setHash: function (hash) {
            this.hash = hash;
        },
        _generateUrl: function () {
            var params = this.params.join('&');
            var baseUrl = this.origUrl.split('?')[0];
            this.url = baseUrl + '?' + params + this.hash;
        }
    };

    return $.extend(that, methods);
};