//Клик по ссылке - изменить товар (rooms/goods_adm)
$(document).on("click", ".goodsUpdate", function (e) {
    e.preventDefault();

    var elem = $(this);
    var good_id = +elem.closest("tr").data("id");

    var clonedElem = $("#newGoodForm").clone();

    clonedElem.append("<input type='hidden' name='good_id' value='" + good_id + "'>");

    var goods_title = $.trim(elem.closest("tr").find(".goods_title").text());
    var goods_cost = $.trim(elem.closest("tr").find(".goods_cost").text());
    var goods_comment = $.trim(elem.closest("tr").find(".goods_comment").text());
    var goods_state = $.trim(elem.closest("tr").find(".goods_state").text()) == "Отключен";

    clonedElem.find("[name='good_title']").val(goods_title);
    clonedElem.find("[name='good_price']").val(goods_cost);
    clonedElem.find("[name='extra']").val(goods_comment);
    clonedElem.find("[name='is_disabled']").prop("checked", goods_state);


    $("#newGoodModal").find(".modal-header-text").text("Изменение товара").end()
        .find(".modal-body").empty().html(clonedElem).end()
        .find(".modal-footer").find("[type='submit']").text("Изменить товар");

    $("#newGoodBtn").click();
});

//Клик по ссылке - изменить тип расхода (rooms/costs_adm)
$(document).on("click", ".typesUpdate", function (e) {
    e.preventDefault();

    var elem = $(this);
    var id = +elem.closest("tr").data("id");

    var clonedElem = $("#newTypeForm").clone();

    clonedElem.append("<input type='hidden' name='type_id' value='" + id + "'>");

    var title = $.trim(elem.closest("tr").find(".types_title").text());
    var state = $.trim(elem.closest("tr").find(".types_state").text()) == "Отключен";

    clonedElem.find("[name='type_title']").val(title);
    clonedElem.find("[name='is_disabled']").prop("checked", state);


    $("#newTypeModal").find(".modal-header-text").text("Изменение типа").end()
        .find(".modal-body").empty().html(clonedElem).end()
        .find(".modal-footer").find("[type='submit']").text("Изменить данные");

    $("#newTypeBtn").click();

});

//Клик по ссылке - изменить подтип расхода (rooms/costs_adm)
$(document).on("click", ".subTypesUpdate", function (e) {
    e.preventDefault();

    var elem = $(this);
    var id = +elem.closest("tr").data("id");

    var clonedElem = $("#newSubTypeForm").clone();

    clonedElem.append("<input type='hidden' name='subtype_id' value='" + id + "'>");

    var title = $.trim(elem.closest("tr").find(".types_title").text());
    var state = $.trim(elem.closest("tr").find(".types_state").text()) == "Отключен";
    var type_id = toNum($.trim(elem.closest("tr").find(".types_type").text()));

    clonedElem.find("[name='subtype_title']").val(title);
    clonedElem.find("[name='subtypes_type_id']").val(type_id);
    clonedElem.find("[name='is_disabled']").prop("checked", state);


    $("#newSubTypeModal").find(".modal-header-text").text("Изменение подтипа").end()
        .find(".modal-body").empty().html(clonedElem).end()
        .find(".modal-footer").find("[type='submit']").text("Изменить данные");

    $("#newSubTypeBtn").click();
});

//Клик по ссылке - изменить тариф (rooms/rates_adm)
$(document).on("click", ".ratesUpdate", function (e) {
    e.preventDefault();

    var elem = $(this);
    var id = +elem.closest("tr").data("id");

    var clonedElem = $("#newRateForm").clone();

    clonedElem.append("<input type='hidden' name='rate_id' value='" + id + "'>");

    var title = $.trim(elem.closest("tr").find(".rates_title").text());
    var formula = $.trim(elem.closest("tr").find(".rates_formula").text());
    var min = $.trim(elem.closest("tr").find(".rates_min").text());
    var max = $.trim(elem.closest("tr").find(".rates_max").text());
    var extra = $.trim(elem.closest("tr").find(".rates_comment").text());
    var state = $.trim(elem.closest("tr").find(".rates_state").text()) == "Отключен";

    clonedElem.find("[name='rate_title']").val(title);
    clonedElem.find("[name='rate_formula']").val(formula);
    clonedElem.find("[name='rate_min']").val(min);
    clonedElem.find("[name='rate_max']").val(max);
    clonedElem.find("[name='extra']").val(extra);
    clonedElem.find("[name='is_disabled']").prop("checked", state);


    $("#newRateModal").find(".modal-header-text").text("Изменение тарифа").end()
        .find(".modal-body").empty().html(clonedElem).end()
        .find(".modal-footer").find("[type='submit']").text("Изменить");

    $("#newRateBtn").click();
});

//Клик по ссылке - изменить подтариф (rooms/options_adm)
$(document).on("click", ".optionsUpdate", function (e) {
    e.preventDefault();

    var elem = $(this);
    var id = +elem.closest("tr").data("id");

    var clonedElem = $("#newOptionForm").clone();

    clonedElem.append("<input type='hidden' name='option_id' value='" + id + "'>");

    var title = $.trim(elem.closest("tr").find(".options_title").text());
    var factor = $.trim(elem.closest("tr").find(".options_factor").text());
    var min = $.trim(elem.closest("tr").find(".options_min").text());
    var max = $.trim(elem.closest("tr").find(".options_max").text());
    var state = $.trim(elem.closest("tr").find(".options_state").text()) == "Отключен";

    clonedElem.find("[name='option_title']").val(title);
    clonedElem.find("[name='option_factor']").val(factor);
    clonedElem.find("[name='option_min']").val(min);
    clonedElem.find("[name='option_max']").val(max);
    clonedElem.find("[name='is_disabled']").prop("checked", state);


    $("#newOptionModal").find(".modal-header-text").text("Изменение подтарифа").end()
        .find(".modal-body").empty().html(clonedElem).end()
        .find(".modal-footer").find("[type='submit']").text("Изменить");

    $("#newOptionBtn").click();
});

//Клик по ссылке - изменить источник траффика (rooms/traffic_sources_adm)
$(document).on("click", ".traffic-source-update", function (e) {
    e.preventDefault();

    var elem = $(this);
    var ts_id = +elem.closest("tr").data("id");

    var modal = $("#newTrafficSourceModal");
    var createForm = $("#newTrafficSourceForm");
    var defaultHeader = modal.find(".modal-header-text").text();
    var defaultBtnText = modal.find(".modal-footer").find("[type='submit']").text();
    var updateForm = createForm.clone();

    updateForm.append("<input type='hidden' name='ts_id' value='" + ts_id + "'>");

    var ts_display_name = $.trim(elem.closest("tr").find(".js-display-name").text());
    var ts_description = $.trim(elem.closest("tr").find(".js-description").text());
    var ts_weight = $.trim(elem.closest("tr").find(".js-weight").text());
    var ts_state = $.trim(elem.closest("tr").find(".js-state").text()) == "Отключен";

    updateForm.find("[name='traffic_source_display_name']").val(ts_display_name);
    updateForm.find("[name='traffic_source_weight']").val(ts_weight);
    updateForm.find("[name='extra']").val(ts_description);
    updateForm.find("[name='is_disabled']").prop("checked", ts_state);
    updateForm.find("[name='name']").closest('.form-group').remove();

    modal.find(".modal-header-text")
        .text("Изменение источника").end()
        .find(".modal-body").html(updateForm).end()
        .find(".modal-footer")
        .find("[type='submit']")
        .text("Изменить источник");

    modal.one('hidden.bs.modal', function () {
        modal.find(".modal-body").html(createForm);
        modal.find(".modal-header-text").text(defaultHeader);
        modal.find(".modal-footer").find("[type='submit']").text(defaultBtnText);
    });
    $("#newTrafficSourceBtn").click();
});