'use strict';

//Предотвращаем действия браузера при клике на .disabled
$(document).on("click", ".disabled", function (e) {
    e.preventDefault();
});

//Обработка события ввода данных при вводе штрих-кода (шапка)
$(document).on('input', "#code", function () {
    var el = $(this);
    if (el.val().length == 13) {
        el.closest("form").submit();
    }
});

//Подтверждение закрытия смены
//ToDo: сделать более абстрактное универсальное подтверждение с сообщением из view
$(document).on("click", "#end_session_12k", function (e) {
    if (!confirm("Вы точно готовы закрыть смену?")) {
        e.preventDefault();
    }
});

//Отработка клика на кнопку дублирования select (создание массива записей)
$(document).on("click", "#add_good_12k, #add_employee_12k", function (e) {
    e.preventDefault();
    var allElems = $(".js-array-field");
    var clonedElem = allElems.last().clone();
    var newName = increaseNumberInString(clonedElem.find("select").attr("name"));
    var newId = increaseNumberInString(clonedElem.find("select").attr("id"));
    clonedElem.find("select").attr("id", newId).attr("name", newName)
        .end().insertBefore($(this).closest("li"));
});


/**** Работа с различными ресурсами ***************/

//Обработка клика на кнопку удаления ресурса
$(document).on("click", ".btn-delete-resource", resourceCrud.delete);

//Обработка клика на переключатель видимости модели
$(document).on("click", ".active-toggle .btn", resourceCrud.toggleActive);

//Обработка клика на переключатель показа активных/всех моделей
$(document).on("click", ".show-disabled-filter .btn", resourceCrud.showDisabled);

//Обработка клика на кнопку создания ресурса
//Сейчас сделал загрузку формы прямо из index, возможно этот вызов еще понядобится
//$(document).on("click", "#newResourceBtn", resourceCrud.createConstructor());

//Клик по кнопке изменения ресурса
$(document).on("click", ".btn-edit-resource", resourceCrud.editConstructor());

//Перезагрузка страницы при закрытии модального окна ресурса, абонемента
$(document).on("hide.bs.modal", ".modal-resource, #newPassModal", function () {
    window.location.reload();
});

//Клик по кнопке изменения данных пользователя (/user)
$(document).on("click", ".btn-edit-user", resourceCrud.editConstructor(function ($modal) {
    $modal.find('select[multiple]').selectpicker({
        countSelectedText: 'Выбрано {0} из {1}'
    });
}));

/*************************************************/


/******** Работа с финансовыми операциями (бывшие расходы) ****************/

//изменение кошелька (источника) (всплывающее окно создания/изменения финансовой операции)
$(document).on("change", ".cash-type", financialOperation.processCashChange);

//Изменение категории (всплывающее окно создания/изменения финансовой операции)
$(document).on("change", ".cost-type", financialOperation.processTypeChange.bind(financialOperation));

//Дивиденды: изменение количества (всплывающее окно создания/изменения финансовой операции)
$(document).on('input', '.dynamic-extra-part .dividends-amount', financialOperation.loadDividends.bind(financialOperation));

//Изменение подтипа (всплывающее окно создания/изменения финансовой операции)
$(document).on('change', '.dynamic-extra-part .cost-subtype', financialOperation.processSubtypeChange.bind(financialOperation));

//Клик по ссылке - изменить данные расхода (rooms/costs)
$(document).on("click", ".costUpdate", financialOperation.loadEditForm);

/****************************************************/


/******** Работа с календарем мероприятий *****************/
$(document).on("click", ".js-month-shift", calendar.loadMonth.bind(calendar));

//загрузка мероприятий на день при клике на дату в календаре
$(document).on("click", ".calendar-container .calendar tbody td", calendar.loadEventsOnDate.bind(calendar));

//загрузка окна работы с мероприятиями на выбранный день
$(document).on("dblclick", ".calendar-container .calendar tbody td", calendar.loadModalOnDate.bind(calendar));

//удаление мероприятия на день
$(document).on("click", ".list-events .delete-event", calendar.deleteEvent.bind(calendar));

//создание мероприятия в определенный день
$(document).on("submit", ".form-create-event", calendar.createEventOnDate.bind(calendar));

//Клик по кнопке изменения типа мероприятия
$(document).on("click", ".btn-edit-event", resourceCrud.editConstructor(function ($modal) {
    $('div.bfh-colorpicker').each(function () {
        var $colorpicker = $(this);
        $colorpicker.bfhcolorpicker($colorpicker.data());
    });
}));

/**********************************************************/


/*********** Работа с именными картами ***************************/

//Клик по ссылке - продать абонемент (/person)
$(document).on("click", ".sellSubscription", function (e) {
    var elem = $(this);
    var code = elem.closest("tr").find(".namedCard_code").text();
    var fio = $.trim(elem.closest("tr").find(".namedCard_fio").text());
    var $form = $('#newPassForm');
    var url = $form.attr('action').replace('-id-', elem.closest('tr').data('id'));

    $form.attr('action', url);
    $('#newPassCardCode').text(code);
    $('#newPassFio').text(fio);
});

//Клик по ссылке - продать абонемент (/person)
$(document).on("change", ".js-pass-switcher", function (e) {
    var $el = $(this);
    $('#endsAt').datepicker('setDate', '+' + $el.data('days'));
});

//Клик по ссылке - изменить данные карты (/person)
$(document).on("click", ".js-person-card-update", function (e) {
    e.preventDefault();

    var elem = $(this);
    var person_id = elem.closest("tr").data("id");
    var url = elem.closest("tr").data("update-url");

    var clonedElem = $("#newCardForm").clone();
    clonedElem.attr("action", url);

    clonedElem.append("<input type='hidden' name='person_id' value='" + person_id + "'>");
    clonedElem.append("<input type='hidden' name='_method' value='put'>");

    var namedCard_fio = elem.closest("tr").find(".namedCard_fio").text();
    var tmp = namedCard_fio.split(String.fromCharCode(160)); //Разбиваем строку по неразрывному пробелу, получая этот пробел как символ от кода
    var namedCard_name = $.trim(tmp[0]);
    var namedCard_surname = $.trim(tmp[1]);
    var namedCard_sex = ~tmp[2].indexOf("м") ? 1 : 2;

    var namedCard_code = elem.closest("tr").find(".namedCard_code").text();
    var namedCard_phone = elem.closest("tr").find(".namedCard_phone").text();
    var namedCard_birthday = elem.closest("tr").find(".namedCard_birthday").text();
    var namedCard_email = elem.closest("tr").find(".namedCard_email").text();
    var namedCard_vk = $.trim(elem.closest("tr").find(".namedCard_vk").text());
    var namedCard_extra = elem.closest("tr").find(".namedCard_extra").text();

    clonedElem.find("[name='code']").val(namedCard_code);
    clonedElem.find("[name='surname']").val(namedCard_surname);
    clonedElem.find("[name='name']").val(namedCard_name);
    clonedElem.find("[name='sex']").prop("checked", false).eq(namedCard_sex - 1).prop("checked", true);
    clonedElem.find("[name='phone']").val(namedCard_phone);
    clonedElem.find("[name='birthday']").val(namedCard_birthday);
    clonedElem.find("[name='email']").val(namedCard_email);
    clonedElem.find("[name='vk']").val(namedCard_vk);
    clonedElem.find("[name='extra']").val(namedCard_extra);


    $("#newCardModal").find(".modal-header-text").text("Изменение данных карты").end()
        .find(".modal-body").empty().html(clonedElem).end()
        .find(".modal-footer").find("[type='submit']").text("Изменить данные");

    $("#newCardBtn").click();
});

/*******************************************************************************/

//Клик по ссылке - удаление (отвязка) именной карты (/person)
$(document).on("click", ".js-person-card-delete", function (e) {
    e.preventDefault();

    var $el = $(e.currentTarget);
    var url = $el.closest("tr").data("updateUrl");

    var name = $el.closest('tr').find('.namedCard_fio').text().replace(/\s+/g, ' ');

    if (confirm('Точно отвязываем карту ' + name)) {
        $.post(url, {_method: 'delete'})
            .done(function (response) {
                $el.closest('tr').remove();
                toastr.success('Карта была отвязана от пользователя');
            });
    }
});