var resourceCrud = {
    createConstructor: function (callback) {
        if (!isset(callback)) {
            callback = function (res) {
                $('body').append(res).find('.modal-resource').modal('show');
            };
        }

        return function (e) {
            e.preventDefault();
            var createRoute = $('.index-resource').data('resource') + '/create';
            $.get(createRoute, callback);
        }
    },
    editConstructor: function (userFunc) {

        return function (e) {
            e.preventDefault();

            var $self = $(this);
            var $modal = $('.modal-resource');
            var id = $self.closest('tr').data('id');
            var resource = $('.index-resource').data('resource');
            var urlEdit = resource + '/' + id + '/edit';
            commonCallback = function () {
                $modal.find('form').attr('action', resource + '/' + id).append('<input type="hidden" name="_method" value="put">');
            };
            $modal.find('.modal-header-text').text('Изменение данных');
            $modal.find('.modal-footer').find('[type="submit"]').text('Изменить');
            $modal.find('.modal-body').load(urlEdit, function () {
                commonCallback();
                if (isset(userFunc)) userFunc($modal);
            });
            $modal.modal('show');
        }
    },
    delete: function (e) {
        e.preventDefault();
        var message = $(this).closest('.index-resource').data('confirm');
        message = message ? message : 'Точно удаляем эту запись? Операция необратима!';

        if (!confirm(message)) {
            return;
        }

        var $self = $(this);
        var id = $self.closest('tr').data('id');
        var csrfToken = $('meta[name="csrf-token"]').attr('content');
        var form = $('<form/>').attr({
                'action': $('.index-resource').data('resource') + '/' + id,
                'method': 'POST'
            })
            .append('<input type="hidden" name="_method" value="delete">')
            .append('<input type="hidden" name="_token" value="' + csrfToken + '">')
            .submit();
    },
    toggleActive: function (e) {
        e.preventDefault();

        var $self = $(this);
        if ($self.hasClass("active")) {
            return;
        }
        var isDisabled = 1;
        if ($self.hasClass("btn-on")) {
            isDisabled = 0;
        }
        var id = $self.closest('tr').data('id');
        var csrfToken = $('meta[name="csrf-token"]').attr('content');
        var $form = $('<form/>').attr({
                'action': $('.index-resource').data('resource') + '/' + id,
                'method': 'POST'
            })
            .append('<input type="hidden" name="_method" value="put">')
            .append('<input type="hidden" name="_token" value="' + csrfToken + '">')
            .append('<input type="hidden" name="is_disabled" value="' + isDisabled + '">');

        $.post($form.attr('action'), $form.serialize(), function (res) {
        });
    },
    showDisabled: function (e) {
        e.preventDefault();

        var $self = $(this);
        if ($self.hasClass("active")) {
            return;
        }
        window.location.assign($('.index-resource').data('resource') + '?show-all=' + $self.find('input').val());
    }
};