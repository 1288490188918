$(function () {
    var $tasksInformer = $("#tasksInformer");
    var $tasksInformerUnseen = $("#tasksInformerUnseen");
    var $tasksInformerUnchecked = $("#tasksInformerUnchecked");

    var jsonResponse, tasks_unseen, tasks_unchecked, wasChanged = false, cond1, cond2;

    tasks_unseen = +$.trim($tasksInformerUnseen.find(".badge").text());
    tasks_unchecked = +$.trim($tasksInformerUnchecked.find(".badge").text());

    var requestTaskUpdates = function () {

        $.get('/tasks/get_task_updates/' + currentUserId, function (res) {
            wasChanged = false;

            jsonResponse = $.parseJSON(res);

            if (jsonResponse.tasks_unseen !== tasks_unseen) {

                $tasksInformerUnseen.find(".badge").text(jsonResponse.tasks_unseen);
                tasks_unseen = jsonResponse.tasks_unseen;
                wasChanged = true;

                if ($tasksInformerUnseen.hasClass("no-display")) {

                    $tasksInformerUnseen
                        .hide()
                        .css({
                            "width": 0,
                            "text-indent": -1000
                        })
                        .show()
                        .removeClass("no-display")
                        .animate({"width": "100%"}, 800)
                        .animate({"text-indent": 0}, 800);
                }
            }
            if (jsonResponse.tasks_unchecked !== tasks_unchecked) {

                $tasksInformerUnchecked.find(".badge").text(jsonResponse.tasks_unchecked);
                tasks_unchecked = jsonResponse.tasks_unchecked;
                wasChanged = true;

                if ($tasksInformerUnchecked.hasClass("no-display")) {

                    $tasksInformerUnchecked
                        .hide()
                        .css({
                            "width": 0,
                            "text-indent": -1000
                        })
                        .show()
                        .removeClass("no-display")
                        .animate({"width": "100%"}, 800)
                        .animate({"text-indent": 0}, 800)
                }
            }

            if (!$tasksInformerUnseen.hasClass("no-display") && tasks_unseen == 0) {
                $tasksInformerUnseen.addClass("no-display");
            }
            if (!$tasksInformerUnchecked.hasClass("no-display") && tasks_unchecked == 0) {
                $tasksInformerUnchecked.addClass("no-display");
            }
        });
    };

    /**
     * Запуск асинхронных запросов для обновления задач
     */
    //setInterval(requestTaskUpdates, 30000);
});