'use strict';
$(document).ready(function () {

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        error: ajaxErrorHandler
    });

    //show alert block for 5 seconds
    $('.alert').fadeIn(400).delay(5000).fadeOut(400);

    //Показ поля ввода номера флаера при выборе соответствующей опции (открытие посещения)
    var $flier = $('.flier-input');
    $('.js-flier-condition').on('change', function (e) {
        if ($(this).find(':selected').data('flier')) {
            $flier.css('list-style-type', 'none').slideDown(600);
        } else {
            $flier.slideUp(600);
        }
    });

    $('select[multiple]').selectpicker({
        countSelectedText: 'Выбрано {0} из {1}'
    });

    //Класс используется на последней ячейке в столбце таблицы и записывает в неё сумму по столбцам
    $(".agregate_val").each(function (i) {
        var $el = $(this);
        var index = $(this).index();
        var tmp = $(this).closest("table").find("tr").not(":last").find("td").filter(function (i, el) {
            return $(this).index() === index;
        });

        var sum = 0;
        tmp.each(function (i) {
            var el = $(this).text();
            sum += toNum(el);
        });

        $el.text(sum);
    });

    $(".format_cash").each(function () {
        $(this).text(currencyFormat($(this).text()));
    });

    $("#new_barcode").length ? $("#new_barcode").focus() : $("#code").focus();
    $(".tabs").tab();
    $(".date, .date_time").each(formatDate);
    $("input[type='tel']").mask("+7 (9?99) 999-9999");
    $(".date_time").mask("99.99.9999 99:99:99");
    $(".date_input").mask("99.99.9999");
    $(".selectpicker").addClass("show-tick").selectpicker();

    var visits_rows_12k = $("#visitsCount");
    var visits_rate_income_12k = $("#visitsRateIncome");
    var visits_goods_income_12k = $("#visitsGoodsIncome");
    var visits_income_12k = $("#visitsIncome");

    if (visits_rows_12k.length) {
        var total_income = 0;
        var row_count = 0;
        var goods_income = 0;
        var rate_income = 0;

        $(".visits_income").each(function (i) {
            var el_text = $(this).text();
            total_income += isNaN(parseInt(el_text)) ? 0 : parseInt(el_text);
            row_count++;
        });

        $(".visits_goods_income").each(function (i) {
            var el_text = $(this).text();
            goods_income += isNaN(parseInt(el_text)) ? 0 : parseInt(el_text);
        });

        $(".visits_rate_income").each(function (i) {
            var el_text = $(this).text();
            rate_income += isNaN(parseInt(el_text)) ? 0 : parseInt(el_text);
        });

        visits_rows_12k.find("b").text(row_count);
        visits_income_12k.find("b").text(total_income + " руб");
        visits_rate_income_12k.find("b").text(rate_income + " руб");
        visits_goods_income_12k.find("b").text(goods_income + " руб");
    }

    var debet = 0;
    $(".debet_12k").each(function (i) {
        var el_text = $(this).text();
        debet += isNaN(parseInt(el_text)) ? 0 : parseInt(el_text);
    });
    var credit = 0;
    $(".credit_12k").each(function (i) {
        var el_text = $(this).text();
        credit += isNaN(parseInt(el_text)) ? 0 : parseInt(el_text);
    });

    var $start_cash = $("#start_cash");
    var start_cash = isNaN(parseInt($start_cash.text())) ? 0 : parseInt($start_cash.text());
    $("#debet_12k").text(debet);
    $("#credit_12k").text(credit);
    $("#cash_12k").text(start_cash + credit - debet);

    $(".datepicker").datepicker();

    $("#from").datepicker({
        maxDate: 0,
        defaultDate: -7,
        changeMonth: true,
        onClose: function (selectedDate) {
            $("#to").datepicker("option", "minDate", selectedDate);
        }
    });
    $("#to").datepicker({
        maxDate: 0,
        changeMonth: true,
        onClose: function (selectedDate) {
            $("#from").datepicker("option", "maxDate", selectedDate);
        }
    });
    $(".date_start").datepicker({
        minDate: 0,
        changeMonth: true,
        onClose: function (selectedDate) {
            $(".date_end").datepicker("option", "minDate", selectedDate);
        }
    });
    $(".date_end").datepicker({
        miDate: +3,
        changeMonth: true,
        onClose: function (selectedDate) {
            $(".date_start").datepicker("option", "maxDate", selectedDate);
        }
    });

    $(".sortable").mySort().myPaginate({
        numPerPage: 50
    });

    $(".sortable").myFilter({
        source: '.test_source',
        target: '.test_target'
    });

    $(".filter_block").find("a").click(function () {
        var $link = $(this);
        var tmp = $link.closest(".dropdown-menu").prev();

        if ($link.hasClass("filter_cancel")) {
            tmp.removeClass("active");
        } else {
            tmp.addClass("active");
        }
    });

    var inter = 0;
    var roomTasksContainer = $("#roomTasks");

    /**
     * Запрос задач для админа 12к
     */
    var requestRoomTasks = function () {

        $.get('/tasks/get_task_sequence/54', function (res) {

            if (res != roomTasksContainer.data('sequence')) {
                $.get('/tasks/get_room_tasks', function (result) {
                    roomTasksContainer.fadeOut(800).html(result).fadeIn(800);

                    roomTasksContainer.data('sequence', res);
                });
            }
        });
    };
    //var timer = setInterval(requestRoomTasks, 60000);

    $('.calendar').find('.pin').first().closest('td').click();
});